const actions = {
  SET_STATE: 'order/SET_STATE',
  SHOW_TRANSACTIONS: 'order/SHOW_TRANSACTIONS',
  SHOW_CLOSE_ORDER_TRANSACTIONS: 'order/SHOW_CLOSE_ORDER_TRANSACTIONS',
  HIDE_OPEN_ORDER_DRAWER: 'order/HIDE_OPEN_ORDER_DRAWER',
  LOAD_REPORT: 'order/LOAD_REPORT',
  HIDE_CLOSE_ORDER_DRAWER: 'order/HIDE_CLOSE_ORDER_DRAWER',
  LOAD_VITALSTATUS: 'order/LOAD_VITALSTATUS',
  LOAD_ALL: 'order/LOAD_ALL',
}

export default actions
