import React from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Layout } from 'antd'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import TopBar from 'components/app/layout/TopBar/TopBarAuth'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({
  appName: settings.appName,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
  // isTopbarFixed: settings.isTopbarFixed,
  isGrayTopbar: settings.isGrayTopbar,
  settings,
})

const AuthLayout = ({
  children,
  appName,
  settings,
  isCardShadow,
  isSquaredBorders,
  isBorderless,
  authPagesColor,
  intl: { formatMessage },
  // isTopbarFixed,
  isGrayTopbar,
}) => {
  console.log('settings', settings)

  const copyrighttext = `${new Date().getFullYear()} ${settings?.logo}`

  return (
    <Layout>
      <Layout.Header
        className={classNames('cui__layout__header', {
          // cui__layout__fixedHeader: isTopbarFixed,
          cui__layout__headerGray: isGrayTopbar,
        })}
      >
        <TopBar />
      </Layout.Header>
      <Layout.Content>
        <div
          className={classNames(`${style.container}`, {
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === 'white',
            // [style.gray]: authPagesColor === 'gray',
          })}
        >
          <img className={style.logo} src="resources/images/logo.png" alt={appName} />
          <></>
          {/* <span style={{ marginTop: '100px' }}>
            <></>
          </span> */}
          <div className={style.containerInner}>{children}</div>
          <div className="mt-auto pb-1 pt-1">
            <div className="text-center m-2">
              {formatMessage({ id: 'text.copyright' }, { copyrighttext })}
            </div>
          </div>
        </div>
      </Layout.Content>
    </Layout>
  )
}

export default withRouter(connect(mapStateToProps)(injectIntl(AuthLayout)))
