import React from 'react'
import { injectIntl } from 'react-intl'
import style from './style.module.scss'

const Footer = ({ intl: { formatMessage } }) => {
  const copyrighttext = `${new Date().getFullYear()} ${process.env.REACT_APP_NAME}`
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <p className="mb-0">
          {' '}
          {formatMessage({ id: 'text.copyrightInnerpage' }, { copyrighttext })}
        </p>
      </div>
    </div>
  )
}

export default injectIntl(Footer)
