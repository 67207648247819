// import { all, call, put, takeLatest } from 'redux-saga/effects'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import loadAll from 'api/tablepaysettings'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { settings } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data1: settings[0].receipt.print_receipt,
        data2: settings[0].receipt.print_merchant_copy,
        data3: settings[0].receipt.footer_line_text,
        data4: settings[0].preferences.idle_timeout,
        data5: settings[0].preferences.is_tip_option,
        data6: settings[0].preferences.tip_options[0],
        data7: settings[0].preferences.tip_options[1],
        data8: settings[0].preferences.tip_options[2],
        // data9: settings[0].preferences.tip_options[3],
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_ALL, LOAD_ALL)])
}
