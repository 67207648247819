import { all, select, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { notification } from 'antd'
import { history } from 'index'
import { login, signup, validateemail, recover, reset } from 'api/user'
import store from 'store'
import RootAction from 'redux/actions'
import MenuAction from 'redux/menu/actions'
import actions from './actions'
import { userStateData } from './reducers'

export function* LOGIN({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  })
  const { response } = yield call(login, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: false,
    },
  })
  if (response) {
    yield call(LOGIN_USER, response)
  }
}

function* LOGIN_USER(response) {
  const { access_token: accessToken, user, businesses } = response.data.data
  store.set(`user.accessToken`, accessToken)
  store.set(`user.data`, user)
  const business = businesses[0]
  const businessLocation = business.locations
  store.set(`user.business`, business)
  store.set(`user.businessLocation`, businessLocation)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken,
      data: user,
      business,
      businessLocation,
    },
  })
  yield history.push('/')
  console.log(MenuAction.GET_DATA)
  yield put({
    type: MenuAction.GET_DATA,
  })
}

export function* CHECK_USER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      email: payload.email,
      registering: true,
    },
  })
  const emailidval = payload.email
  const { response } = yield call(validateemail, emailidval)
  store.set(`user.email`, payload.email)
  yield put({
    type: actions.SET_STATE,
    payload: {
      registering: false,
    },
  })

  if (response.data.is_valid === 0) {
    yield put({
      type: 'user/SET_STATE',
    })
    notification.warning({
      message: 'Email Already Exists',
      description: 'Please enter the different email for signup!',
    })
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        first_name: payload.first_name,
        last_name: payload.last_name,
        phone_number: payload.phone_number,
        password: payload.password,
        email: payload.email,
        agent_id: payload.agent_id,
        signUpStep: 1,
      },
    })
  }
}

export function* REGISTER({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      submitting: true,
    },
  })
  const user = yield select(state => state.user)
  const { first_name, last_name, email, phone_number, password, agent_id } = user
  const { type, name, address, province, city, country, postal_code, partner_id } = payload
  const { response } = yield call(signup, {
    first_name,
    last_name,
    email,
    phone_number,
    password,
    agent_id,
    type,
    name,
    address,
    province,
    city,
    country,
    postal_code,
    partner_id,
  })

  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: userStateData,
    })
    notification.success({
      message: 'Succesful Registered',
      description: 'You have successfully registered!',
    })
    yield call(CLEAR_USER)
    yield history.push('/')
  } else {
    yield put({
      type: actions.SET_STATE,
      payload: {
        signUpStep: 0,
        submitting: false,
      },
    })
  }
}

export function* LOGOUT() {
  yield call(CLEAR_USER)
}

export function* CLEAR_USER() {
  store.clearAll()
  yield put({
    type: RootAction.CLEAR_STATE,
  })
  yield put({
    type: actions.SET_STATE,
    payload: userStateData,
  })
  // yield history.push('/')
}

export function* RECOVER_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: true,
    },
  })
  const { response, error } = yield call(recover, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      recovering: false,
    },
  })
  if (response) {
    yield history.push('/')
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        recover: errors,
      },
    })
  }
}

export function* RESET_PASSWORD({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: true,
    },
  })
  const { response, error } = yield call(reset, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      resetting: false,
    },
  })
  if (response) {
    const { access_token } = response.data
    yield call(HANDLE_USER_TOKEN, access_token)
  } else {
    const { errors } = error.response?.data
    yield put({
      type: actions.SET_ERROR_STATE,
      payload: {
        reset: errors,
      },
    })
  }
}

function* HANDLE_USER_TOKEN(access_token) {
  store.set(`user.accessToken`, access_token)
  yield put({
    type: actions.SET_STATE,
    payload: {
      accessToken: access_token,
      email: null,
      canResendEmail: false,
      resendEmail: false,
    },
  })
  yield history.push('/login')
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOGIN, LOGIN),
    takeEvery(actions.REGISTER, REGISTER),
    takeLatest(actions.LOGOUT, LOGOUT),
    takeLatest(actions.CHECK_USER, CHECK_USER),
    takeLatest(actions.CLEAR_USER, CLEAR_USER),
    takeLatest(actions.RECOVER_PASSWORD, RECOVER_PASSWORD),
    takeLatest(actions.RESET_PASSWORD, RESET_PASSWORD),
  ])
}
