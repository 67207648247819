/* eslint-disable camelcase */
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadAll, loadcloseorder, loadvitalstatus } from 'api/order'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { orders } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        openorderdata: orders,
      },
    })
  }
}

export function* LOAD_VITALSTATUS() {
  const { response } = yield call(loadvitalstatus)
  if (response) {
    const { order_info } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        vitaldata: order_info,
      },
    })
  }
}

export function* LOAD_REPORT({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })

  const { response } = yield call(loadcloseorder, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    const { orders } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        closedorderdata: orders,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.LOAD_REPORT, LOAD_REPORT),
    takeLatest(actions.LOAD_VITALSTATUS, LOAD_VITALSTATUS),
  ])
}
