export default async function getMenuData() {
  return [
    {
      category: true,
      title: 'Manage',
    },
    {
      title: 'Manage Orders',
      key: 'manage-orders',
      icon: 'fe fe-list',
      url: '/manage/orders/open-order',
      extra_urls: ['/manage/orders/closed-order'],
    },

    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-users',
      url: '/manage/user',
    },

    {
      title: 'Device Info',
      key: 'device-info',
      icon: 'fe fe-tablet',
      url: '/manage/device-info/windows-client',
      extra_urls: ['/manage/device-info/payment-terminal'],
    },
    {
      title: 'Store Info',
      key: 'store-info',
      icon: 'fe fe-shopping-bag',
      url: '/manage/store-info',
    },
  ]
}
