import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadAll, loadLogs } from 'api/deviceslist'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { devices } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data: devices,
      },
    })
  }
}

export function* LOAD_LOGS(payload) {
  const deviceId = payload.payload
  const { response } = yield call(loadLogs, deviceId)
  if (response) {
    const { logs } = response.data.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        data2: logs,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([takeLatest(actions.LOAD_ALL, LOAD_ALL), takeLatest(actions.LOAD_LOGS, LOAD_LOGS)])
}
