import apiClient from 'services/axios'

const BASE_URL = 'user'

const BASE_URL1 = 'signup'

export async function signup(payload) {
  return apiClient
    .post(`${BASE_URL1}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function register(payload) {
  return apiClient
    .post(`${BASE_URL}/register`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function login(payload) {
  return apiClient
    .post(`${BASE_URL}/login`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function validateemail(payload) {
  return apiClient
    .get(`${BASE_URL}/verify/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function recover(payload) {
  return apiClient
    .post(`${BASE_URL}/recover`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function reset(payload) {
  return apiClient
    .post(`${BASE_URL}/reset`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
