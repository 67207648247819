/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  openorderdata: null,
  closedorderdata: null,
  vitaldata: null,
  // selectedData: null,
  selectedDataone: '',
  selectedDatatwo: '',
  search: null,
  searchopenorders: null,
  searchclosedorders: null,
  displayTransactionHistoryDrawer: false,
  displayCloseOrderSummaryDrawer: false,
}

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SHOW_TRANSACTIONS:
      return {
        ...state,
        selectedDataone: action.payload.order,
        displayTransactionHistoryDrawer: true,
      }

    case actions.HIDE_OPEN_ORDER_DRAWER:
      return { ...state, displayTransactionHistoryDrawer: false }

    case actions.SHOW_CLOSE_ORDER_TRANSACTIONS:
      return {
        ...state,
        selectedDatatwo: action.payload.order,
        displayCloseOrderSummaryDrawer: true,
      }

    case actions.HIDE_CLOSE_ORDER_DRAWER:
      return { ...state, displayCloseOrderSummaryDrawer: false }

    default:
      return state
  }
}
