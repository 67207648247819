/* eslint-disable camelcase */
import apiClient from 'services/axios'

const BASE_URL = 'business'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update_timezone(payload) {
  // console.log("payload value is")
  // console.log(payload)
  return apiClient
    .put(`${BASE_URL}/storeinfo`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
