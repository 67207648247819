import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import staff from './staff/reducers'
import order from './order/reducers'
import business from './business/reducers'
import settings from './settings/reducers'
import tablepaysettings from './tablepaysettings/reducers'
import devicelist from './devicelist/reducers'
import country from './country/reducers'
import softwarelist from './softwarelist/reducers'
import RootAction from './actions'

export default history => {
  const appReducer = combineReducers({
    router: connectRouter(history),
    user,
    menu,
    staff,
    order,
    tablepaysettings,
    settings,
    business,
    devicelist,
    country,
    softwarelist,
  })
  return (state, action) => {
    if (action.type === RootAction.CLEAR_STATE) {
      state = undefined
    }
    return appReducer(state, action)
  }
}
