import store from 'store'
import actions from './actions'

const STORED_USER = storedUser => {
  const user = {}
  Object.keys(storedUser).forEach(key => {
    const item = store.get(`user.${key}`)
    user[key] = typeof item !== 'undefined' ? item : storedUser[key]
  })
  return user
}

export const userStateData = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  password: '',
  agent_id: '',
  type: '',
  name: '',
  address: '',
  city: '',
  province: '',
  restaurant_software: '',
  partner_id: '',
  country: '',
  postal_code: '',
  signUpStep: 0,
  accessToken: null,
  businessLocation: null,
  business: null,
  data: null,
  recovering: false,
  creating: false,
  submitting: false,
  resetting: false,
  loading: false,
  changingEmail: false,
  canResendEmail: true,
  confirming: false,
  resendingEmail: false,

  errors: {
    register: null,
    change: null,
    login: null,
    recover: null,
    reset: null,
    business: null,
    submitting: null,
  },
}

const initialState = {
  ...STORED_USER(userStateData),
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_ERROR_STATE:
      return { ...state, errors: { ...state.errors, ...action.payload } }
    default:
      return state
  }
}
