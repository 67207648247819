import apiClient from 'services/axios'

const BASE_URL = 'device_list'
const BASE_URL1 = 'logs'

export async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadLogs(deviceId) {
  return apiClient
    .get(`${BASE_URL1}/${deviceId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
