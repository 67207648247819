import apiClient from 'services/axios'

const BASE_URL = 'settings'

export default async function loadAll(payload) {
  return apiClient
    .get(`${BASE_URL}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
