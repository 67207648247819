const actions = {
  SET_STATE: 'devicelist/SET_STATE',
  SHOW_CREATE: 'devicelist/SHOW_CREATE',
  SHOW_SETTINGS: 'devicelist/SHOW_SETTINGS',

  LOAD_ALL: 'devicelist/LOAD_ALL',
  LOAD_LOGS: 'devicelist/LOAD_LOGS',
}

export default actions
