import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, Dropdown, Menu } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ user }) => ({ user })

const ProfileMenu = ({ dispatch, user }) => {
  const [count] = useState(0)

  const userActiveLogin = user?.data?.staff?.name
  const userActiveBusiness = user?.business?.name
  const userRole = user?.data?.staff?.role

  // const role_value=userRole.toCapitalize()

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <i className="fe fe-user mr-2" />
        {userActiveLogin}
      </Menu.Item>
      <Menu.Item style={{ textTransform: 'capitalize' }}>
        <i className="fe fe-users mr-2" />
        {userRole}
      </Menu.Item>
      <Menu.Item>
        <i className="fe fe-shopping-bag mr-2" />
        {userActiveBusiness}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className="fe fe-log-out mr-2" />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
      {/* <Menu.Item>
        <div>
          <strong className="mr-1">
            <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
          </strong>
          Professional
        </div>
      </Menu.Item> */}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className={styles.dropdown}>
        <Badge count={count}>
          <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
