/* eslint-disable no-underscore-dangle */
import actions from './actions'

const initialState = {
  data: null,
  data2: null,
  // selectedData: null,
  search: null,
  searchpaymentterminal: null,
  searchwindowsclient: null,
  selectedCloverDevice: '',
  selectedWindowsDevice: '',
  displayLogDrawer: false,
  displaysettingsDrawer: false,
  creating: false,
}

export default function devicelistReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_CREATE:
      return { ...state, selectedWindowsDevice: action.payload.devices, displayLogDrawer: true }
    case actions.SHOW_SETTINGS:
      return {
        ...state,
        selectedCloverDevice: action.payload.devicename,
        displaysettingsDrawer: true,
      }
    default:
      return state
  }
}
