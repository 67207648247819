import React from 'react'
import LanguageSwitcher from '../LanguageSwitcher'
import style from './style.module.scss'

const TopBar = () => {
  const path = window.location.pathname
  return (
    <div className={style.topbar}>
      <div className="mr-4" />
      <div className="mr-auto" />
      <div className="mr-4 d-none d-md-block" />
      <div className="mb-0 mr-auto d-xl-block d-none" />
      <div className="mr-4 d-none d-sm-block">
        <LanguageSwitcher />
      </div>

      <div className="mr-4 d-none d-sm-block" />
      {path === '/register' && (
        <div>
          <img
            className={style.logo}
            src="resources/images/logo.png"
            alt="Table Pay"
            title="Table Pay"
          />
        </div>
      )}
    </div>
  )
}

export default TopBar
