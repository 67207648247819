import localeAntd from 'antd/es/locale/fr_FR'

const messages = {
  'text.currentlyserving': 'Actuellement en Service',
  'text.users': 'Utilisateurs',
  'text.totalCount': '{count, plural, =1{1 enregistrement} other{# enregistrements}}',
  'text.totalordervalue': 'Valeur totale de la commande',
  'text.servers': 'Les Serveurs',
  'text.orderlist': 'Gérer les Commandes',
  'text.openorders': 'Commandes Ouvertes',
  'text.closedorders': 'Commandes Fermées',
  'text.tables': 'Les Tables',
  'text.ordercreatedon': 'Créé',
  'text.ordertableno': 'Numéro de table',
  'text.ordertype': 'Type de commande',
  'text.orderpaymentstatus': 'Statut du paiement',
  'text.orderseatno': 'Numéro de siège',
  'form.label.name': 'Nom du contact',
  'form.label.active': 'Statut',
  'text.space': '{count, plural, =1{1} other{#}}',

  // Open Order Table Header

  'text.openorder.sno': 'S.No',
  'text.openorder.orderno': 'Numéro de Commande',
  'text.openorder.ordertype': 'Type de Commande',
  'text.openorder.tableno': 'Numéro de Table',
  'text.openorder.seatno': 'Numéro de Siège',
  'text.openorder.servername': 'Nom du Serveur',
  'text.openorder.ordertotal': 'Total de la Commande',
  'text.openorder.paymenttype': 'Type de Paiement',
  'text.openorder.paymentstatus': 'Statut du paiement',
  'text.openorder.search': 'Recherche par numéro de commande',
  'text.openorder.orderdetail': 'Détail de la commande',

  // Close Order Table Header

  'text.closeorder.sno': 'S.No',
  'text.closeorder.orderno': 'Numéro de Commande',
  'text.closeorder.ordertype': 'Type de Commande',
  'text.closeorder.tableno': 'Numéro de Table',
  'text.closeorder.seatno': 'Numéro de Siège',
  'text.closeorder.servername': 'Nom du Serveur',
  'text.closeorder.ordertotal': 'Total de la Commande',
  'text.closeorder.paymenttype': 'Type de Paiement',
  'text.closeorder.orderdetail': 'Détail de la commande',
  'text.closeorder.paymentstatus': 'Statut du paiement',
  'text.closeorder.search': 'Recherche par numéro de commande',

  // *******************************Store Settings*******************************/

  'text.storedetails': 'Détails du Magasin',
  'form.label.storeName': 'Nom du Magasin',
  'form.error.storeName.required': 'Veuillez saisir le nom du magasin',
  'form.placeholder.storeName': 'Entrez le nom du magasin',
  'form.label.address': 'Adresse',
  'form.error.address.required': "Veuillez saisir l'adresse",
  'form.placeholder.address': "Entrez l'adresse",
  'form.label.city': 'Ville',
  'form.error.city.required': 'Veuillez entrer la ville',
  'form.placeholder.city': 'Entrez la Ville',
  'form.label.province': 'Province / État',
  'form.error.province.required': "Veuillez sélectionner la province / l'état",
  'form.label.selectprovince': "Veuillez sélectionner la province / l'état",
  'form.label.postalCode': 'Code Postal / Zip',
  'form.error.postalCode.required': 'Veuillez saisir le code postal / zip',
  'form.placeholder.postalCode': 'Entrez le Code Postal / zip',
  'form.label.country': 'Pays',
  'form.error.country.required': 'Veuillez sélectionner le pays',
  'form.label.selectcountry': 'Choisissez le Pays',
  'text.contactdetails': 'Détails du Contact',
  'form.label.email': 'Email Id',

  'form.label.emailaddress': "l'adresse e-mail",
  'form.placeholder.emailaddress': "l'adresse e-mail",
  'form.error.email.required': "Veuillez saisir l'adresse e-mail",
  'form.error.email.type': 'Adresse e-mail invalide',
  'form.error.email.max': "L'adresse e-mail doit contenir jusqu'à 25 caractères",
  'form.placeholder.email': "Entrez l'e-mail",
  'form.label.mobile': 'Numéro du Mobile',
  'form.error.mobile.required': 'Veuillez saisir le numéro du mobile',
  'form.placeholder.mobile': '(123) 456-7890',
  'form.label.alternatenumber': 'Numéro Alterné',
  'form.error.alternatenumber.required': 'Veuillez saisir un autre numéro de mobile',
  'form.placeholder.alternatenumber': '(123) 456-7890',
  'text.preferences': 'Préférences',
  'form.label.language': 'Langue Préférée',
  'form.error.language.required': 'Veuillez sélectionner la langue préférée',
  'form.placeholder.language': 'Sélectionnez la langue préférée',
  'form.label.timezone': 'Fuseau Horaire',
  'form.error.timezone.required': 'Veuillez sélectionner le fuseau horaire',
  'form.placeholder.timezone': 'Sélectionnez le fuseau horaire',
  'text.businessdetails': "Détails de l'entreprise",
  'form.label.businessid': "ID de l'entreprise",
  'form.label.locationid': "ID de l'emplacement",
  'form.placeholder.businessid': "Entrez le numéro d'identification d'entreprise à 4 chiffres",
  'form.placeholder.locationid': "Entrez l'identifiant de l'emplacement à 4 chiffres",
  'form.label.storetype': 'Type de magasin',
  'text.storesettings': 'Paramètres du magasin',

  // *******************************Manage Staff*******************************/

  'text.stafflist': 'Gérer le Personnel',
  'action.createstaff': 'Créer du Personnel',
  'text.createstaff': 'Créer du Personnel',
  'text.editstaff': 'Mettre à jour le personnel',
  'text.active': 'Actif',
  'text.inactive': 'Inactif',
  'text.edit': 'Éditer',
  'form.label.firstName': 'Prénom',
  'form.error.firstName.required': 'Veuillez saisir le prénom',
  'form.error.firstName.max': "Le prénom doit comporter jusqu'à 20 caractères",
  'form.placeholder.firstName': 'Entrez le Prénom',
  'form.label.lastName': 'Nom de Famille',
  'form.error.lastName.required': 'Veuillez saisir le nom de famille',
  'form.error.lastName.max': "Le nom de famille doit comporter jusqu'à 20 caractères",
  'form.placeholder.lastName': 'Entrez le nom de famille',
  'form.label.role': 'Rôle',
  'form.error.role.required': 'Veuillez sélectionner le rôle',
  'form.placeholder.role': 'Sélectionnez le rôle',
  'form.label.password': 'Mot de Passe',
  'form.error.password.required': 'Veuillez saisir le mot de passe',
  'form.placeholder.password': 'Entrer le mot de passe',
  'form.label.repassword': 'Retaper le mot de passe',
  'form.error.repassword.required': 'Retaper le mot de passe',
  'form.placeholder.repassword': 'Retaper le mot de passe',
  'form.label.loginPin': 'PIN de Connexion',
  'form.error.loginpin.required': 'Veuillez saisir un code PIN de connexion à 4 chiffres',
  'form.placeholder.loginPin': 'Entrez le code PIN de connexion à 4 chiffres',
  'form.label.retypeloginpin': 'Retaper le code PIN de connexion',
  'form.error.retypeloginpin.required':
    'Veuillez saisir à nouveau le code PIN de connexion à 4 chiffres',
  'form.placeholder.retypeloginpin': 'Entrez à nouveau le code PIN de connexion à 4 chiffre',

  // View Staff Table Header

  'text.viewstaff.sno': 'S.No',
  'text.viewstaff.name': 'Nom',
  'text.viewstaff.email': 'Email',
  'text.viewstaff.role': 'Rôle',
  'text.viewstaff.status': 'Statut',
  'text.viewstaff.action': 'Action',
  'text.viewstaff.mobile': 'Numéro de portable',
  'action.remove': 'Supprimer',

  // *******************************Table Pay Settings*******************************/

  'text.tablepaysettings': 'Paramètres de paiement de table',
  'text.receiptsettings': 'Paramètres du reçu',
  'form.label.idletimeout': "Délai d'inactivité",
  'form.error.idletimeout.required': "Délai d'inactivité requis",
  'form.placeholder.idletimeout': '0 signifie désactivé',
  'form.label.tipamount': 'Options de Pourboire',
  'form.label.tip': 'Pourboire',
  'form.label.tipoptions': 'Choisissez exactement 3 options',
  'form.label.giftcard': "Voulez-vous activer l'option Carte-cadeau?",
  'form.label.printreceipt': 'Imprimer le reçu une fois la commande terminée?',
  'form.label.printmerchantcopy': 'Imprimer une copie du commerçant une fois la commande terminée?',
  'form.label.footerline1': 'Pied de page ligne 1',
  'form.label.footerline2': 'Pied de page ligne 2',
  'form.label.footerline3': 'Pied de page ligne 3',
  'form.label.footerline4': 'Pied de page ligne 4',
  'form.label.footerline': ' Texte de la ligne de pied de page ',
  'form.error.footerline1.required': 'Veuillez saisir le texte',
  'form.error.footerline2.required': 'Veuillez saisir le texte',
  'form.error.footerline3.required': 'Veuillez saisir le texte',
  'form.error.footerline4.required': 'Veuillez saisir le texte',
  'form.placeholder.footerline1': 'Veuillez saisir le texte',
  'form.placeholder.footerline2': 'Veuillez saisir le texte',
  'form.placeholder.footerline3': 'Veuillez saisir le texte',
  'form.placeholder.footerline4': 'Veuillez saisir le texte',

  // *******************************Device Info*******************************/

  'text.deviceinfo': 'Info Appareil',
  'text.deviceinfo.macaddress': 'Adresse MAC',
  'text.deviceinfo.devicetype': 'Type de périphérique',
  'text.devicelogs': 'Journaux des périphériques',

  // Device Info

  // S. No’ (Numéro d'information de l'appareil)

  'text.deviceinfo.sno': 'S.No',
  'text.deviceinfo.devicename': "Nom de l'appareil",
  'text.deviceinfo.ipaddress': 'Adresse IP',
  'text.deviceinfo.lastactivity': 'Dernière Activité',
  'text.deviceinfo.networkstatus': "L'état du réseau",
  'text.deviceinfo.logs': 'Registres',
  'text.deviceinfo.viewlogs': 'Afficher les registres',

  // Helmet Title
  'title.helmet.deviceinfo': 'Info Appareil',
  'title.helmet.orders': 'Gérer les Commandes',
  'title.helmet.createstaff': 'Créer du Personnel',
  'title.helmet.updatestaff': 'Mettre à jour le personnel',
  'title.helmet.viewstaff': 'Gérer le Personnel',
  'title.helmet.store': 'Paramètres du Magasin',
  'title.helmet.tablepaysettings': 'Réglages',

  // General Language Options

  'form.placeholder.search': 'Chercher..',
  'action.save': 'Sauvegarder',
  'action.submit': 'Soumettre',
  'action.view': 'Afficher les Registres',
  'action.update': 'Mise à Jour',
  'action.create': 'Créer',
  'action.yes': 'Oui',
  'action.no': 'Non',
  'text.enable': 'Activer',
  'text.disable': 'Désactiver',
  'action.enable': 'Activer',
  'action.edit': 'Éditer',
  'action.disable': 'Désactiver',
  'text.online': 'En Ligne',
  'action.offline': 'Hors Ligne',
  'action.signIn': 'Se Connecter',
  'action.forgotPassword': 'Mot de passe oublié?',
  'action.signUp': "S'inscrire",
  'action.next': 'Prochain',
  'action.gotosignin': 'Aller à la connexion',

  'topBar.issuesHistory': 'Historique des Problèmes',
  'topBar.projectManagement': 'Gestion de Projet',
  'topBar.typeToSearch': 'Chercher...',
  'topBar.findPages': 'Rechercher des pages ...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Statut',
  'topBar.profileMenu.hello': 'Bonjour',
  'topBar.profileMenu.billingPlan': 'Plan de Facturation',
  'topBar.profileMenu.role': 'Rôle',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Téléphone',
  'topBar.profileMenu.editProfile': 'Editer le Profil',
  'topBar.profileMenu.logout': 'Se Déconnecter',
  'text.copyright': "Droits d'auteur © 2020 {appName}. Tous droits réservés.",

  // *******************************Login*******************************/

  'text.signIn.title': 'Connectez-vous à votre compte',
  'text.dontHaveAccount': "Vous n'avez pas de compte? ",

  // *******************************Signup*******************************/

  'text.stepper.ownerProfile': 'Profil du Propriétaire',
  'text.stepper.businessProfile': "Profil d'entreprise",
  'title.register': "S'inscrire",
  'text.ownerProfile.title': 'Profil du Propriétaire',
  'text.ownerProfile.description':
    "Parlez-nous du propriétaire de l'entreprise pour lancer votre candidature.",
  'form.error.password.min': "Le mot de passe doit être d'au moins 8 caractères",
  'form.error.password.max': "Le mot de passe doit contenir jusqu'à 10 caractères",
  'form.label.agentid': "ID d'agent",
  'form.placeholder.agentid': "Entrez l'ID de l'agent",
  'text.businessProfile.title': "Profil d'entreprise",
  'text.businessProfile.description':
    'Parlez-nous de votre entreprise afin que nous puissions vous aider à la configurer pour accepter les paiements.',
  'form.label.businesstype': "Type d'entreprise",
  'form.label.businessName': "Nom de l'entreprise",
  'form.error.businessName.required': "Veuillez saisir le nom de l'entreprise",
  'form.error.businessName.max': "Le nom de l'entreprise doit comporter jusqu'à 64 caractères",
  'form.placeholder.businessName': "Entrez le nom de l'entreprise",
  'form.label.businessAddress': "Adresse de l'entreprise",
  'form.error.businessAddress.required': "Veuillez saisir l'adresse de l'entreprise",
  'form.error.businessAddress.max':
    "L'adresse professionnelle doit comporter jusqu'à 180 caractères",
  'form.placeholder.businessAddress': 'Adresse de rue',
}

export default {
  locale: 'fr-FR',
  localeAntd,
  messages,
}
